.allied-text-button {
  color: $red;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  transition: 100ms;

  &:hover {
    opacity: 0.8;
  }
}
