.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 10 !important;
}

.react-datepicker__triangle {
  transform: translate(40px, 0px) !important;
}

.react-datepicker__navigation--years {
  background-image: none !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
