$plus-icon-size: 34px;

.plus-icon {
  border-radius: 50%;
  width: $plus-icon-size;
  height: $plus-icon-size;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  &__helper {
    background-color: white;
    border-radius: 50%;
    width: calc(#{$plus-icon-size} * 0.8);
    height: calc(#{$plus-icon-size} * 0.8);
  }

  img {
    position: absolute;
    width: $plus-icon-size;
    height: $plus-icon-size;
    cursor: pointer;
    border-radius: 50%;

    &:hover {
      filter: brightness(0.8);
    }
  }
}
