@import "colors";
@import "bootstrap-override";
@import "./react-bootstrap-table";
@import "utils";
@import "mixins";
@import "pages";
@import "components";

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
body {
  margin: 0;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
}

h1 {
  font-weight: 900;
}

h2, h3 {
  font-weight: 700;
}

h4 {
  font-weight: 500;
}

a {
  color: $red;
  &:hover {
    color: $red;
  }
}

.pointer-events-none {
  pointer-events: none !important;
}

.cursor-pointer {
  cursor: pointer;
}
