.input-file {
  display: flex;
  flex-direction: row;
  align-items: center;
  &__button {

  }
  &__text-render {
    @extend .pl-3;
    display: flex;
    &--hover {
      &:hover {
        font-weight: bold;
      }
    }
  }
}