.allied-paginator-limit-controller {
  display: flex;
  flex-direction: column;

  overflow-x: auto;
  max-width: 100%;

  &__top {
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__bottom {
    margin-top: 10px;

    &__range-label {
      font-style: italic;
      font-size: calc(#{$paginatorTextAndIconSize} * 0.85);
    }
  }

  &__label {
    font-size: $paginatorTextAndIconSize;
    margin-right: 20px;
    margin-bottom: 0;
  }

  &__select-container {
    min-width: 80px;
  }
}
