.allied-radio-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .custom-radio {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}
