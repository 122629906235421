.create-user-select-sites-modal {
  &_check-con {
    .custom-checkbox {
      margin-bottom: 0;
      display: inline-block;
    }

    label {
      margin-bottom: 0;
    }
  }
}