.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 2.18rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 700;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    /* When the checkbox is checked, add a purple background */
    &:checked ~ .checkmark {
      //background-color: $purple;

      /* Show the checkmark when checked */
      &:after {
        display: block;
      }
    }
  }

  /* Create a custom checkbox */
  .checkmark {
    @extend .rounded;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    border: 0.15rem solid $red;
  }

  /* On mouse-over, add a purple background color */
  &:hover input ~ .checkmark {
    //background-color: $purple;
  }
}