.allied-auto-complete {
  position: relative;

  input {
    transition: all 0s !important;
    -webkit-transition: all 0s !important;
    -moz-transition: all 0s !important;
    -ms-transition: all 0s !important;
    -o-transition: all 0s !important;
  }

  &-list {
    background-color: $white;
    margin-top: -3px;
    position: absolute;
    border: $allied-input-border;
    border-top: none;
    border-bottom-left-radius: $allied-input-border-radius;
    border-bottom-right-radius: $allied-input-border-radius;
    width: 100%;
    z-index: 1;
    max-height: 200px;
    overflow-y: scroll;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    &-empty-item {
      font-style: italic;
      margin-bottom: 0;
      padding: 6px 12px;
      font-size: 11pt;
      color: $darkGray;
      opacity: 0.6;
    }

    &-item {
      cursor: pointer;
      margin-bottom: 0;
      padding: 6px 12px;
      font-size: 11pt;
      color: $darkGray;
      opacity: 0.6;
      font-weight: normal;

      &:hover {
        color: $black;
        opacity: 1;
      }
    }

    .list-item-selected {
      opacity: 1;
      color: $black;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  span {
    @extend .allied-input-drop-down-arrow;
    background-color: $white;
    padding-left: 5px;
  }
}
