.allied-container {
  width: 100%;
  padding: 1.5rem;
  position: relative;

  @media screen and (min-width: $sideBarMobileVisibleWidth) {
    padding: 3rem;
  }

  &-bg {
    opacity: 0.75;
    object-fit: contain;
    width: 60vw;
    position: fixed;
    bottom: 3vh;
    right: 3vw;
    z-index: -1;
    user-select: none;

    @media screen and (min-width: $sideBarMobileVisibleWidth) {
      width: 40vw;
    }
  }
}
