label {
  font-weight: bold;
}

$placeholder: (
        '::-webkit-input-placeholder',
        ':-moz-placeholder',
        '::-moz-placeholder',
        ':-ms-input-placeholder',
        '::input-placeholder',
        '::placeholder'
);

@each $selector in $placeholder {
  #{$selector} {
    font-style: italic;
  }
}

$allied-input-border: 0.125rem solid $black;
$allied-input-border-radius: 0.25rem;

input {
  @extend .px-3;
  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
  outline: none;

  border: 0.125rem solid $darkGray;
  border-radius: $allied-input-border-radius;
  height: 2.75rem;
  width: 100%;

  &:focus, {
    border: $allied-input-border;
  }

  &[type="file"] {
    border-width: 0;
    font-style: italic;

    & i {
      color: red;
    }
  }
}

textarea {
  @extend input;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;

  padding: 0.5rem 0;
  height: auto;
  min-height: 8rem;
}

.input-error {
  @extend input;
  border: 0.125rem solid $red;
  caret-color: $red;

  &::placeholder {
    color: $red;
    opacity: 0.75;
  }

  &:focus, {
    border: 0.125rem solid $red;
  }
}

.allied-input-drop-down-arrow {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  margin: 0 auto;
  padding: 0;
  font-weight: bold;
  color: $red;
}

.allied-custom-select {
  background-color: $white;
  position: relative;

  select {
    @extend .px-3;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    outline: none;

    appearance: none;

    position: relative;
    z-index: 1;
    background-color: transparent;
    border: 0.125rem solid $darkGray;
    border-radius: 0.25rem;
    height: 2.75rem;
    width: 100%;
  }

  span {
    @extend .allied-input-drop-down-arrow;
  }
}
