.crane-qr-printable {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  &_text {
    &_info {
      text-align: center;
    }

    &_crane-details {
      text-align: center;
    }
  }

  &_qr {
    margin-top: 80px;
    width: 60%;
  }

  &_access {
    margin-top: 80px;

    h2 {
      text-align: center;
    }

    h1 {
      text-align: center;
      font-family: "Roboto Mono", monospace;
    }
  }

  &_under {
    margin-top: 80px;

    &_instructions {
      text-align: center;
    }
  }
}