.allied-paginator-container {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 730px) {
    flex-direction: column;
    align-items: center !important;

    .allied-paginator-limit-controller {
      margin-bottom: 20px;

      &__bottom {
        &__range-label {
          text-align: center;
        }
      }
    }

    .allied-paginator-offset-controller {
      align-self: center;
    }
  }
}
