.nav-bar-h5 {
  font-size: 14pt;
  color: white;

  &:hover {
    opacity: 0.85;
  }
}

.sidebar {
  width: 340px;
  max-width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background-color: $red;
}

.sidebar-inner {
  width: 100%;

  &-header {
    padding: 1rem;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-width: 75%;
      object-fit: contain;
      margin-bottom: 1.5rem;
    }

    h5 {
      font-size: 14pt;
      text-align: center;
      color: $red;
      margin-bottom: 0;
    }
  }

  &-divider {
    height: 12px;
    background-color: $black;
  }

  &-navigation {
    padding: 1rem 1.5rem;
    user-select: none;

    &-entry {

      &-header-container {
        display: flex;
        color: $white;
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.25s, height 2s;

        &:hover {
          opacity: 0.85;
        }

        h5 {
          @extend .nav-bar-h5;
          margin-bottom: 0.6rem;
          display: flex;
          align-items: center;
        }

        &-collapse-icon {
          font-size: 18pt;
          margin-left: 0.4rem;
          margin-bottom: -1px;
          transition: transform 0.5s;
          max-height: 55px;
        }

        &-collapse-icon-closed {
          transform: rotateZ(-90deg);
        }
      }

      &-list {
        padding-left: 1.5rem;
        overflow-y: hidden;
        transition: height 0.5s ease-in-out;

        .sidebar-inner__custom-button {
          margin-left: -4px;
        }

        a, .sidebar-inner__custom-button {
          display: block;
          color: $white !important;
          font-weight: normal;
          margin-bottom: 0.6rem !important;
          cursor: pointer;
          opacity: 0.7;
          transition: opacity 0.25s;
          background-color: transparent !important;
          border: none;
          font-size: 1rem !important;
          padding: 0 !important;
          outline: none !important;
          box-shadow: none !important;

          &:hover {
            text-decoration: none;
            opacity: 1;
          }

          &.active-link {
            opacity: 1 !important;
          }
        }
      }
    }
  }
}

$sideBarMobileVisibleWidth: 1300px;

.sidebar-header-toggle-icon {
  /* this value is also used in the SideBarManager.tsx file for matching the screen width */
  @media screen and (min-width: $sideBarMobileVisibleWidth) {
    display: none !important;
  }
}
