.auto-complete-list-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;

  &-item {
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-right: 10px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    span {
      font-size: 11pt;
      font-style: italic;
      margin-right: 7px;
    }

    &-close-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background-color: $red;

      &-x {
        color: $white;
        width: 90%;
      }
    }
  }
}
