@import "src/style/colors";

/* The custom-radio */
.custom-radio {
  display: flex;
  position: relative;
  padding-left: 2.18rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: normal;
  white-space: nowrap;
  margin-bottom: 0;

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: auto;
    width: auto;
  }

  /* On mouse-over, add a gray background color */
  &:hover input ~ .checkmark {
    background-color: $gray;
  }

  /* When the radio button is checked, add a red background */
  input:checked ~ .checkmark {
    background-color: $red;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    border: 0.13rem solid $red;

    /* Create the indicator (the dot/circle - hidden when not checked) */
    &:after {
      content: "";
      position: absolute;
      display: none;

      /* Style the indicator (dot/circle) */
      top: 0;
      left: 0;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
      background: $red;
      border: 0.2rem solid $gray;
    }
  }
}
