$paginatorTextAndIconSize: 16px;

.allied-paginator-offset-controller {
  user-select: none;
  display: inline-flex;
  flex-direction: row;
  align-self: flex-start;
  padding: 5px;
  background-color: $white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 5px;

  overflow-x: auto;
  max-width: 100%;

  &__button {
    padding: 5px 13px;
    font-size: $paginatorTextAndIconSize;
    margin-right: 5px;
    border-radius: 7px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__selected-offset {
    background-color: $red;
    cursor: initial;
    color: $white;
  }

  &__selectable-offset {
    cursor: pointer;

    &:hover {
      background-color: $gray;
    }
  }

  &__paginator-icon {
    max-height: $paginatorTextAndIconSize;
  }

  &__arrow-container {
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  &__disabled-arrow {
    color: $darkGray;
    opacity: 0.5;
  }
}
