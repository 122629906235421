.allied-input-list-generic {
  width: 100%;

  .row {
    flex: 1 !important;
  }

  &__entry {
    margin-bottom: 0.7rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &__remove-row {
      padding-right: 48px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      span {
        color: $red;
        font-size: 10pt;
        cursor: pointer;

        &:hover {
          filter: brightness(0.8);
        }
      }
    }

    &__nested-remove {
      .allied-input-list-generic__entry__remove-row {
        padding-right: 0;
      }
    }

    &__plus-spacer {
      padding-bottom: 5px;
      padding-left: 0.75rem;
    }

    &__plus-spacer-hidden {
      opacity: 0;
      height: 0;
      user-select: none;

      img {
        z-index: -1000;
      }
    }
  }
}

.padding-top-2_3rem {
  padding-top: 2.3rem !important;
}
