.allied-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    margin-bottom: 0;
  }

  &_filter-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    cursor: pointer;
    color: $red;
    transition: opacity 0.1s;

    &:hover {
      opacity: 0.85;
    }

    &_icon {
      @include svg-sizer(20px);
    }
  }
}
