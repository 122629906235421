.allied-input-list {
  @extend .shadow;

  &__header {
    background-color: black;
    padding: 1rem;
    border-radius: 5px;
    overflow: hidden;

    h5 {
      color: white;
      margin-bottom: 0;
    }
  }

  &__body {
    border: 1px solid $gray;

    &__item {
      padding: 1rem 1.5rem;
      background-color: $white;

      &:nth-of-type(2n) {
        background-color: $gray;
      }
    }

    &__empty {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
