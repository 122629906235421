// put overrides here
@import "colors";
@import "node_modules/bootstrap/scss/bootstrap";

$enable-gradients: false;

$theme-colors: (
        "red": $red,
        "black": $black,
        "white": $white,
        "gray": $gray,
        "dark-gray": $darkGray,
);

@import "~bootstrap";

.btn {
  min-width: 12rem;
  max-width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 10pt;

  &-white {
    @extend .shadow;
    color: $black;
  }

  &-outline {

    &-white {
      @extend .shadow;
    }
  }
}
