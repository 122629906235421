.printable-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__logo {
    height: 90px;
    object-fit: contain;
  }

  &__info {
    p {
      text-align: right;
      margin-bottom: 0;
      font-size: 12px;
    }
  }
}
