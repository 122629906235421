.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &-background-container {
    width: 100%;
    height: 100vh;
    display: none;
    overflow: hidden;
    background-color: $red;

    @media screen and (min-width: 1400px) {
      display: flex;
      width: 75%;
    }

    & > img {
      mix-blend-mode: multiply;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-content {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 100%;
    max-height: 660px;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow: hidden;

    & > div {
      @extend .shadow;
      display: flex;
      width: 100%;
      max-width: 800px;
      overflow: hidden;
      border-radius: 0.5rem;
      flex-direction: column;
      margin: 0 1.5rem;

      @media screen and (min-width: 500px) {
        margin: 0 2rem;
      }

      @media screen and (min-width: 1400px) {
        margin: 0 3rem;
      }

      @include media-breakpoint-up(md) {
        flex-direction: row;
        margin: 0 4rem;
      }

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        margin: 0 5rem;
      }
    }

    &__image-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-up(md) {
        width: 60%;
        justify-content: flex-end;
      }

      & > img {
        max-width: 320px;
        padding: 0 2rem;
      }
    }

    &__form-container {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      background-color: $red;
      padding: 1rem 1.5rem;

      @media screen and (min-width: 500px) {
        padding: 1.5rem 2rem;
      }

      @media screen and (min-width: 1400px) {
        padding: 2rem 3rem;
      }

      @include media-breakpoint-up(md) {
        border-left: 2rem solid $black;
      }

      &__version-label {
        margin-top: 2rem;

        @include media-breakpoint-up(md) {
          margin-top: 1rem;
        }

        p {
          margin-bottom: 0;
          text-align: center;
          color: $white;
          font-size: 0.9rem;
        }
      }

      &__form {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;

        .btn {
          @media screen and (max-width: 400px) {
            min-width: 0 !important;
            width: 100%;
          }
        }
      }

    }
  }
}
