$allied-printable-cell-padding: 3px 6px;
$allied-printable-font-size: 14px;

.printable {
  font-size: $allied-printable-font-size;

  p {
    margin-bottom: 0 !important;
  }

  &__header {
    font-weight: bold;
    font-size: 24px;
  }

  &__sub-header {
    font-weight: bold;
    font-size: 14px;
  }

  &__cell-row {
    width: 100%;
    display: flex;
    flex-direction: row;

    @media print {
      break-inside: avoid !important;
    }
  }

  &__cell {
    padding: $allied-printable-cell-padding;
    border: 1px solid black;
    border-left-width: 0;

    &:first-of-type {
      border-left-width: 1px;
    }
  }

  &__cell-label-with-value {
    display: flex;
    flex-direction: row;

    &__label {
      font-weight: bold;
      margin-right: 5px;
    }

    &__value {

    }
  }

  &__text-area {
    @media print {
      break-inside: avoid;
    }

    &__label {
      font-weight: bold;
      margin-bottom: 5px;
    }

    &__value {
      white-space: pre;
    }
  }

  &__table {
    width: 100%;
    font-size: $allied-printable-font-size;

    td {
      border: 1px solid black;
      padding: $allied-printable-cell-padding;
      vertical-align: top;

      white-space: -o-pre-wrap;
      word-wrap: break-word;
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
    }

    &__label {
      font-weight: bold;
      white-space: nowrap !important;
    }

    &__centered {
      text-align: center;
    }

    &__nowrap {
      white-space: nowrap;
    }

    &__break-word {
      word-break: break-word;
    }

    &__spacer-row {
      border: none !important;

      td {
        border: none !important;
      }
    }

    &__flex-column {
      display: flex;
      flex-direction: column;
    }

    &__no-padding {
      padding: 0 !important;
    }

    &__white-space-pre {
      white-space: pre;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
  }

  &__react-icon {
    color: black;
    height: 18px;
    width: auto;
  }
}
