.allied-modal {
  padding: 1.5rem;

  hr {
    width: 100%;
  }
}

.allied-modal-header {
  display: flex;
  justify-content: space-between;

  &-title-and-back {
    display: flex;
    align-items: center;
  }

  h3 {
    margin-bottom: 0;
  }

  &-icon {
    cursor: pointer;
    color: $red;
    transition: opacity 0.1s;
    max-width: 14px;

    &:hover {
      opacity: 0.85;
    }
  }

  &-back-icon {
    cursor: pointer;
    color: $black;
    transition: opacity 0.1s;
    margin-right: 0.5rem;
    transform: rotate(-90deg);
    height: 10px;
    margin-top: -1px;

    &:hover {
      opacity: 0.85;
    }
  }
}
