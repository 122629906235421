.allied-table-container {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  .content-table {
    margin: 0;
  }

  table {
  }

  thead > tr > th {
    background-color: $black !important;
  }

  .allied-table-header-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;

    h6 {
      color: $white;
      margin-bottom: 0;
      font-weight: 600;
      font-size: 13pt;
    }

    .allied-sorting-icons-container {
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      &:hover {
        opacity: 0.9;
      }

      &-top {
        transform: rotate(180deg);
      }

      &-icon {
        padding: 3px;
        opacity: 0.5;
        transition: all 0.15s;
      }
    }
  }

  .allied-table-cell {
    display: flex;
  }

  .justify-content-center {
    .allied-table-header-cell,
    .allied-table-cell {
      justify-content: center;
    }
  }

  tbody {
    border-bottom: none;
  }

  tbody > tr {
    border-bottom: none;
    background-color: $white;

    &:nth-child(even) {
      background-color: #f9f6f6;
    }

    &:last-of-type {
      border: none;
      //border-bottom: 1px solid $darkGray !important;
    }
  }
}

.allied-table-header-sort-asc {
  .allied-sorting-icons-container-top {
    opacity: 1 !important;
  }
}

.allied-table-header-sort-desc {
  .allied-sorting-icons-container-bottom {
    opacity: 1 !important;
  }
}
